var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Companies")]),_c('fab',{attrs:{"icon":"add","id":"company-add"},on:{"click":_vm.startNewCompany}}),_c('p',{staticClass:"company-count"},[_vm._v(" "+_vm._s(((_vm.$t('company.total_confirmed')) + ": " + (_vm.companies ? _vm.companies.total_confirmed : '0')))+" ")]),_c('div',{staticClass:"is-clearfix"},[_c('div',{staticClass:"field is-horizontal is-pulled-right"},[_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"input",attrs:{"placeholder":_vm.$t('_.search') + '...'},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value},_vm.searchDebounce]}}),_vm._m(0)])])]),(_vm.loading)?_c('div',{directives:[{name:"t",rawName:"v-t",value:('messages.loading'),expression:"'messages.loading'"}]}):_c('company-login-list',{attrs:{"companies":_vm.companies.items,"columns":[
      'id',
      'name',
      'status',
      'accountant',
      'package',
      'nextInvoiceNumber' ]},on:{"edit":_vm.onEdit}}),_c('div',{staticClass:"archive-pagination-buttons"},[_c('a',{staticClass:"icon",class:{ 'is-disabled': !_vm.companies || _vm.companies.current <= 1 },on:{"click":function($event){return _vm.loadPage(1, _vm.searchQuery)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("first_page")])]),_c('a',{staticClass:"icon",class:{ 'is-disabled': !_vm.companies || _vm.companies.current <= 1 },on:{"click":_vm.prevPage}},[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_left")])]),_c('a',{staticClass:"icon",class:{
        'is-disabled': !_vm.companies || _vm.companies.current >= _vm.companies.last,
      },on:{"click":_vm.nextPage}},[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_right")])]),_c('a',{staticClass:"icon",class:{
        'is-disabled': !_vm.companies || _vm.companies.current >= _vm.companies.last,
      },on:{"click":function($event){return _vm.loadPage(_vm.companies.last, _vm.searchQuery)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("last_page")])])]),(_vm.newCompany)?_c('company-edit-modal',{attrs:{"company":_vm.newCompany,"handler":_vm.onNewComplete},on:{"close":function($event){_vm.newCompany = null}}}):_vm._e(),(_vm.editingCompany)?_c('company-edit-modal',{attrs:{"editing":true,"company":_vm.editingCompany,"handler":_vm.onEditComplete},on:{"close":function($event){_vm.editingCompany = null}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"material-icons"},[_vm._v("search")])])}]

export { render, staticRenderFns }