
































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CompanyExt } from '@/models';
import { AdminService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import CompanyLoginList from '@/components/CompanyLoginList.vue';
import CompanyEditModal from '@/components/modals/CompanyEditModal.vue';
import Fab from '@/components/Fab.vue';
import { Paginated } from '@/lib/types';
import { debounce } from 'lodash-es';

@Component({
  components: {
    CompanyLoginList,
    CompanyEditModal,
    Fab,
  },
})
export default class AdminCompaniesPage extends Vue {
  loading: boolean = true;
  companies: Paginated<CompanyExt> | null = null;

  editingCompany: CompanyExt | null = null;
  newCompany: CompanyExt | null = null;
  searchQuery: string = '';

  searchDebounce = debounce(this.search, 200);

  async created(): Promise<void> {
    await this.refresh();
  }

  async nextPage(): Promise<void> {
    const page = (this.companies && this.companies.next) || 1;
    await this.loadPage(page, this.searchQuery);
  }

  async prevPage(): Promise<void> {
    const page = (this.companies && this.companies.previous) || 1;
    await this.loadPage(page, this.searchQuery);
  }

  async search(): Promise<void> {
    await this.loadPage(1, this.searchQuery);
  }

  async refresh(): Promise<void> {
    const page = (this.companies && this.companies.current) || 1;
    await this.loadPage(page);
  }

  private async loadPage(page: number, q?: string): Promise<void> {
    this.loading = true;
    try {
      this.companies = await AdminService.getCompanies(page, q);
    } catch (e) {
      this.$toaster.error('Error loading companies', unwrapError(e));
    }
    this.loading = false;
  }

  onEdit(c: CompanyExt): void {
    this.editingCompany = c;
  }

  startNewCompany(): void {
    this.newCompany = new CompanyExt();
  }

  async onNewComplete(c: CompanyExt): Promise<void> {
    try {
      await AdminService.createCompany(c);
      this.$toaster.success(
        this.$tc('messages.success.created.company'),
        c.name,
      );
      this.newCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.company'),
        unwrapError(e),
      );
    }
  }

  async onEditComplete(c: CompanyExt): Promise<void> {
    try {
      await AdminService.updateCompany(c);
      this.$toaster.success(
        this.$tc('messages.success.update.company'),
        c.name,
      );
      this.editingCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.company'),
        unwrapError(e),
      );
    }
  }
}
